var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-form",
        {
          attrs: { enctype: "multipart/form-data" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitCreate.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Informações básicas" },
                  },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "form-row" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "8" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Título "),
                                  ]),
                                  _c("b-form-input", {
                                    class: {
                                      "is-invalid": _vm.$v.item.title.$error,
                                    },
                                    model: {
                                      value: _vm.item.title,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "title", $$v)
                                      },
                                      expression: "item.title",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "" } },
                                    [
                                      _vm._v(" #ID - MIGRAR "),
                                      _c("feather-icon", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover.v-dark",
                                            modifiers: {
                                              hover: true,
                                              "v-dark": true,
                                            },
                                          },
                                        ],
                                        staticClass: "text-info",
                                        attrs: {
                                          size: "16",
                                          icon: "HelpCircleIcon",
                                          title:
                                            "Caso esteja migrando o produto da versão anterior da Seducar, insira o ID do produto, localizado na tabela de Produtos Digitais > Curso online.",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    attrs: { type: "number" },
                                    model: {
                                      value: _vm.item.id_old,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "id_old", $$v)
                                      },
                                      expression: "item.id_old",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-1",
                                  class: {
                                    "is-invalid":
                                      _vm.$v.item.description.$error,
                                  },
                                },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _c("i", {
                                      staticClass:
                                        "text-danger bi bi-record-circle",
                                    }),
                                    _vm._v(" Descrição "),
                                  ]),
                                  _c("editor", {
                                    staticClass: "about-editor-1",
                                    attrs: {
                                      "api-key":
                                        "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                      init: this.optionEditor({
                                        height: 600,
                                        selector: "about-editor-1",
                                      }),
                                    },
                                    model: {
                                      value: _vm.item.description,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "description", $$v)
                                      },
                                      expression: "item.description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0 mt-1" },
                                [
                                  _c("label", { attrs: { for: "" } }, [
                                    _vm._v("Sobre o produto - "),
                                    _c("small", [
                                      _vm._v(
                                        "Será exibido no final da página."
                                      ),
                                    ]),
                                  ]),
                                  _c("editor", {
                                    staticClass: "about-editor-2",
                                    attrs: {
                                      "api-key":
                                        "22tjm5j5x697634ds1wb41evwoynudmdq6pao8yine3sb5yp",
                                      init: this.optionEditor({
                                        height: 300,
                                        selector: "about-editor-2",
                                      }),
                                    },
                                    model: {
                                      value: _vm.item.about,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "about", $$v)
                                      },
                                      expression: "item.about",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Sales Force", "header-class": "mb-1" },
                  },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "form-group mb-2" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "custom-control-primary",
                              model: {
                                value: _vm.item.sales_force_positions,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.item,
                                    "sales_force_positions",
                                    $$v
                                  )
                                },
                                expression: "item.sales_force_positions",
                              },
                            },
                            [_vm._v(" Exibir cargos (Sales Force) ")]
                          ),
                        ],
                        1
                      ),
                      _vm.item.sales_force_positions
                        ? _c(
                            "div",
                            { staticClass: "form-group mb-0" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Cargos "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  staticClass: "v-select-tags",
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: _vm.optionsSalesForcePositions,
                                  },
                                  on: {
                                    search: _vm.fetchSalesForcePositions,
                                    input: _vm.positionSelected,
                                  },
                                  model: {
                                    value: _vm.selectPosition,
                                    callback: function ($$v) {
                                      _vm.selectPosition = $$v
                                    },
                                    expression: "selectPosition",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                              _vm.item.positions
                                ? _c(
                                    "draggable",
                                    {
                                      staticClass: "list-group mt-1",
                                      attrs: {
                                        tag: "ul",
                                        list: _vm.item.positions,
                                        handle: ".handle",
                                      },
                                    },
                                    _vm._l(
                                      _vm.item.positions,
                                      function (element, idx) {
                                        return _c(
                                          "li",
                                          {
                                            key: element.code,
                                            staticClass: "list-group-item",
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "handle",
                                              attrs: {
                                                icon: "MoveIcon",
                                                size: "15",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "pl-1 text-not-selected",
                                              },
                                              [_vm._v(_vm._s(element.title))]
                                            ),
                                            _c("feather-icon", {
                                              staticClass:
                                                "float-right cursor-pointer text-danger",
                                              attrs: {
                                                icon: "XCircleIcon",
                                                size: "20",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeAtPosition(
                                                    idx
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _c("p", { staticClass: "mt-1 mb-0" }, [
                                _vm._v(
                                  "Os cargos serão listados no formulário de interesse, na página do produto."
                                ),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            { staticClass: "form-group mb-0" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Sales force "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  ref: "selectSalesForce",
                                  staticClass: "text-elipse-300",
                                  class: {
                                    "is-invalid": _vm.$v.item.salesforce.$error,
                                  },
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o título",
                                    options: _vm.optionsSalesForce,
                                  },
                                  on: { search: _vm.fetchSalesForce },
                                  model: {
                                    value: _vm.item.salesforce,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.item, "salesforce", $$v)
                                    },
                                    expression: "item.salesforce",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      on: {
                                        click: function ($event) {
                                          _vm.$refs.selectSalesForce.open = false
                                        },
                                      },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
                  },
                  [
                    _c("div", { staticClass: "card-header mb-2" }, [
                      _c("div", { staticClass: "form-row" }, [
                        _c("div", { staticClass: "col-md-10 col-8" }, [
                          _c("h4", { staticClass: "mb-0" }, [
                            _vm._v(" Turmas "),
                            _c("small", { staticClass: "d-block" }, [
                              _vm._v(
                                "Vincule as turmas relacionadas a esta live"
                              ),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "col-md-2 col-4" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "ripple",
                                    rawName: "v-ripple.400",
                                    value: "rgba(255, 255, 255, 0.15)",
                                    expression: "'rgba(255, 255, 255, 0.15)'",
                                    modifiers: { 400: true },
                                  },
                                ],
                                staticClass: "btn-block",
                                attrs: { variant: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$bvModal.show("modal-classroom")
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "bi bi-plus mr-25" }),
                                _c("span", [_vm._v("Novo")]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c(
                          "draggable",
                          {
                            staticClass: "list-group mt-1",
                            attrs: {
                              tag: "ul",
                              list: this.item.classrooms,
                              handle: ".handle",
                            },
                          },
                          _vm._l(
                            this.item.classrooms,
                            function (element, index) {
                              return _c(
                                "li",
                                { key: index, staticClass: "list-group-item" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex-list-items-dragable" },
                                    [
                                      _c("feather-icon", {
                                        staticClass: "handle cursor-pointer",
                                        attrs: { icon: "MoveIcon", size: "15" },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pl-1 text-not-selected",
                                        },
                                        [_vm._v(_vm._s(element.title) + " ")]
                                      ),
                                      _c("feather-icon", {
                                        staticClass:
                                          "float-right cursor-pointer text-danger",
                                        attrs: {
                                          icon: "XCircleIcon",
                                          size: "20",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeAtClassroom(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                        !_vm.item.classrooms.length
                          ? _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                " Nenhuma turma foi selecionada até o momento. "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Disciplinas exibidas no site" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "div",
                          {
                            ref: "listDisciplines",
                            staticClass: "repeater-form",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.repeateAgain.apply(null, arguments)
                              },
                            },
                          },
                          _vm._l(
                            _vm.item.disciplineslive,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  ref: "row",
                                  refInFor: true,
                                  staticClass: "form-row",
                                  attrs: { id: item.id },
                                },
                                [
                                  _c("b-col", { attrs: { md: "9" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "title",
                                            type: "text",
                                            placeholder: "Título da disciplina",
                                          },
                                          model: {
                                            value: item.title,
                                            callback: function ($$v) {
                                              _vm.$set(item, "title", $$v)
                                            },
                                            expression: "item.title",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("b-col", { attrs: { md: "2" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "time",
                                            type: "number",
                                            placeholder: "hora(s)",
                                          },
                                          model: {
                                            value: item.time,
                                            callback: function ($$v) {
                                              _vm.$set(item, "time", $$v)
                                            },
                                            expression: "item.time",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("b-col", { attrs: { md: "1" } }, [
                                    _c(
                                      "div",
                                      { staticClass: "form-group" },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(234, 84, 85, 0.15)",
                                                expression:
                                                  "'rgba(234, 84, 85, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            staticClass: "mt-0",
                                            attrs: {
                                              variant: "outline-danger",
                                              block: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeItem(index)
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-25",
                                              attrs: { icon: "Trash2Icon" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _c("b-col", { attrs: { md: "12" } }, [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: item.description,
                                            expression: "item.description",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          placeholder: "Descrição",
                                          rows: "4",
                                        },
                                        domProps: { value: item.description },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              item,
                                              "description",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]),
                                  ]),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "ripple",
                                rawName: "v-ripple.400",
                                value: "rgba(255, 255, 255, 0.15)",
                                expression: "'rgba(255, 255, 255, 0.15)'",
                                modifiers: { 400: true },
                              },
                            ],
                            attrs: { variant: "primary" },
                            on: { click: _vm.repeateAgain },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-25",
                              attrs: { icon: "PlusIcon" },
                            }),
                            _c("span", [_vm._v("Adicionar")]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Sites de buscas" } },
                  [
                    _c("b-card-text", [
                      _c("p", { staticClass: "mb-3" }, [
                        _vm._v(
                          ' Caso queira personalizar o título, descrição e url para mecanismos de buscas(Googlem Bing e Yahoo), clique em "Editar SEO do site" e preencha os campos abaixo. '
                        ),
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Título SEO"),
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.title_seo,
                                  expression: "item.title_seo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: _vm.item.title_seo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "title_seo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("URL"),
                              ]),
                              _c(
                                "b-input-group",
                                {
                                  staticClass: "input-group-merge",
                                  attrs: { prepend: "/cursos/lives/" },
                                },
                                [
                                  _c("b-form-input", {
                                    staticClass: "text-primary pl-1",
                                    model: {
                                      value: _vm.item.slug,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "slug", $$v)
                                      },
                                      expression: "item.slug",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Descrição para SEO"),
                            ]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.item.description_seo,
                                  expression: "item.description_seo",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { maxlength: "320" },
                              domProps: { value: _vm.item.description_seo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.item,
                                    "description_seo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-1",
                    attrs: { header: "Imagem de destaque" },
                  },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          { staticClass: "form-row" },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("input", {
                                  staticClass: "form-control-file hidden",
                                  attrs: {
                                    id: "image",
                                    type: "file",
                                    accept: "image/*",
                                  },
                                  on: { change: _vm.previewImage },
                                }),
                                _c("b-form-group", [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "d-block text-center",
                                      staticStyle: {
                                        cursor: "pointer",
                                        "font-size": "1.1rem",
                                      },
                                      attrs: { for: "image" },
                                    },
                                    [
                                      !_vm.preview
                                        ? _c(
                                            "div",
                                            { staticClass: "pt-5 py-4" },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  size: "19",
                                                  icon: "ImageIcon",
                                                },
                                              }),
                                              _vm._v(" Selecionar imagem "),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.preview
                                        ? [
                                            _c("b-img", {
                                              staticStyle: {
                                                width: "100%",
                                                height: "200px",
                                              },
                                              attrs: {
                                                for: "image",
                                                src: _vm.preview,
                                                fluid: "",
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", [
                                      _vm._v("Vídeo de apresentação"),
                                    ]),
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "text",
                                        placeholder: "Link do YouTube/Vimeo",
                                      },
                                      model: {
                                        value: _vm.item.video_url,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "video_url", $$v)
                                        },
                                        expression: "item.video_url",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c("label", [
                                  _c("i", {
                                    staticClass:
                                      "text-danger bi bi-record-circle",
                                  }),
                                  _vm._v(" Label da imagem "),
                                ]),
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.label_image.$error,
                                      },
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.item.label_image,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "label_image", $$v)
                                        },
                                        expression: "item.label_image",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "small",
                                  {
                                    staticClass: "d-block",
                                    staticStyle: { "margin-top": "4px" },
                                  },
                                  [
                                    _vm._v(
                                      " Será exibido sobre a imagem de destaque. "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Detalhes" } },
                  [
                    _c("b-card-text", [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-12" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Ficha técnica do concurso "),
                              ]),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "v-select",
                                    {
                                      ref: "selectDatasheet",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.datasheet.$error,
                                      },
                                      attrs: {
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder: "Digite o título",
                                        options: _vm.optionsDatasheets,
                                      },
                                      on: { search: _vm.fetchDatasheets },
                                      model: {
                                        value: _vm.item.datasheet,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "datasheet", $$v)
                                        },
                                        expression: "item.datasheet",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          on: {
                                            click: function ($event) {
                                              _vm.$refs.selectDatasheet.open = false
                                            },
                                          },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("b-col", { attrs: { md: "12" } }, [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Tempo de acesso "),
                                _c("small", [_vm._v("(dias) ")]),
                              ]),
                              _c("input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["####"],
                                    expression: "['####']",
                                  },
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.validity,
                                    expression: "item.validity",
                                  },
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid": _vm.$v.item.validity.$error,
                                },
                                attrs: {
                                  type: "tel",
                                  min: "1",
                                  placeholder: "365",
                                },
                                domProps: { value: _vm.item.validity },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.item,
                                      "validity",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-12" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Subcategoria "),
                              ]),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "v-select",
                                    {
                                      ref: "selectSub",
                                      class: {
                                        "is-invalid":
                                          _vm.$v.item.subcategory.$error,
                                      },
                                      attrs: {
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder: "Digite o título",
                                        options: _vm.optionsSubcategories,
                                      },
                                      on: { search: _vm.fetchSubcategories },
                                      model: {
                                        value: _vm.item.subcategory,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "subcategory", $$v)
                                        },
                                        expression: "item.subcategory",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          on: {
                                            click: function ($event) {
                                              _vm.$refs.selectSub.open = false
                                            },
                                          },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-12 col-12" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _c("i", {
                                  staticClass:
                                    "text-danger bi bi-record-circle",
                                }),
                                _vm._v(" Área(s) "),
                              ]),
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "v-select",
                                    {
                                      ref: "selectAreas",
                                      class: {
                                        "is-invalid": _vm.$v.item.areas.$error,
                                      },
                                      attrs: {
                                        multiple: "",
                                        label: "title",
                                        "item-text": "title",
                                        "item-value": "code",
                                        placeholder: "Digite o título",
                                        options: _vm.optionsAreas,
                                      },
                                      on: { search: _vm.fetchAreas },
                                      model: {
                                        value: _vm.item.areas,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "areas", $$v)
                                        },
                                        expression: "item.areas",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "no-options" },
                                          on: {
                                            click: function ($event) {
                                              _vm.$refs.selectAreas.open = false
                                            },
                                          },
                                          slot: "no-options",
                                        },
                                        [_vm._v(" Nenhum registro encontrado ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Preço" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          { staticClass: "form-row" },
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _c("i", {
                                        staticClass:
                                          "text-danger bi bi-record-circle",
                                      }),
                                      _vm._v(" Preço "),
                                    ]),
                                    _c(
                                      "money",
                                      _vm._b(
                                        {
                                          staticClass: "form-control",
                                          class: {
                                            "is-invalid":
                                              _vm.$v.item.price.$error,
                                          },
                                          attrs: { placeholder: "0,00" },
                                          model: {
                                            value: _vm.item.price,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item, "price", $$v)
                                            },
                                            expression: "item.price",
                                          },
                                        },
                                        "money",
                                        _vm.money,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(" Promocional "),
                                    ]),
                                    _c(
                                      "money",
                                      _vm._b(
                                        {
                                          staticClass: "form-control",
                                          attrs: { placeholder: "0,00" },
                                          model: {
                                            value: _vm.item.sale,
                                            callback: function ($$v) {
                                              _vm.$set(_vm.item, "sale", $$v)
                                            },
                                            expression: "item.sale",
                                          },
                                        },
                                        "money",
                                        _vm.money,
                                        false
                                      )
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("label", { attrs: { for: "" } }, [
                                      _vm._v(" Promoção válida até "),
                                    ]),
                                    _c("flat-pickr", {
                                      staticClass: "form-control",
                                      attrs: {
                                        config: {
                                          dateFormat: "d/m/Y",
                                          altFormat: "Y-m-d",
                                          locale: "pt",
                                        },
                                      },
                                      model: {
                                        value: _vm.item.promotion_date,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "promotion_date",
                                            $$v
                                          )
                                        },
                                        expression: "item.promotion_date",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Opcionais" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c("b-row", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col-12 mb-1" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Depoimento(s) "),
                                  _c("small", [_vm._v("(Aprovados)")]),
                                ]),
                                _c(
                                  "v-select",
                                  {
                                    ref: "selectApproved",
                                    attrs: {
                                      multiple: "",
                                      label: "title",
                                      "item-text": "title",
                                      "item-value": "code",
                                      placeholder: "Digite o título",
                                      options: _vm.optionsApproveds,
                                    },
                                    on: { search: _vm.fetchApproveds },
                                    model: {
                                      value: _vm.item.approveds,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.item, "approveds", $$v)
                                      },
                                      expression: "item.approveds",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "no-options" },
                                        on: {
                                          click: function ($event) {
                                            _vm.$refs.selectApproved.open = false
                                          },
                                        },
                                        slot: "no-options",
                                      },
                                      [_vm._v(" Nenhum registro encontrado ")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_store_discount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_store_discount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.disable_store_discount",
                                      },
                                    },
                                    [_vm._v(" Não aplicar desconto em loja ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_billet,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_billet",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_billet",
                                      },
                                    },
                                    [_vm._v(" Desabilitar venda no boleto ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_popup,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_popup",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_popup",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Desativar Pop-up de interessado "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_form_contact,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_form_contact",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_form_contact",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Desativar formulário de contato "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.disable_whatsapp,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "disable_whatsapp",
                                            $$v
                                          )
                                        },
                                        expression: "item.disable_whatsapp",
                                      },
                                    },
                                    [_vm._v(" Desativar botão do WhatsApp ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { staticClass: "mb-0" },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticClass: "custom-control-primary",
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.item.plataforma_questoes,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.item,
                                            "plataforma_questoes",
                                            $$v
                                          )
                                        },
                                        expression: "item.plataforma_questoes",
                                      },
                                    },
                                    [_vm._v(" Acesso a Plataf. de Questões ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Tags" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { md: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  { staticClass: "mb-0" },
                                  [
                                    _c("b-form-tags", {
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": _vm.$v.item.tags.$error,
                                      },
                                      attrs: {
                                        separator: ",;",
                                        "no-add-on-enter": "",
                                        placeholder: "",
                                      },
                                      model: {
                                        value: _vm.item.tags,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.item, "tags", $$v)
                                        },
                                        expression: "item.tags",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "mb-0 mt-2" }, [
                          _vm._v("Adicione tags separando-as por vírgula."),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-card",
                  { staticClass: "mb-1", attrs: { header: "Status" } },
                  [
                    _c(
                      "b-card-text",
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-0" },
                          [
                            _c("v-select", {
                              class: {
                                "is-invalid": _vm.$v.item.status.$error,
                              },
                              attrs: {
                                label: "title",
                                options: [
                                  { title: "Ativo", code: 1 },
                                  { title: "Inativo", code: 0 },
                                ],
                              },
                              model: {
                                value: _vm.item.status,
                                callback: function ($$v) {
                                  _vm.$set(_vm.item, "status", $$v)
                                },
                                expression: "item.status",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("ButtonsActionsFooter", {
                  attrs: {
                    routerBack: "products-lives-list",
                    variant: "success",
                    submited: _vm.submited,
                    text: "Cadastrar",
                    subtext: "Aguarde...",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-classroom",
            centered: "",
            title: "Vincular Turma(s)",
            "cancel-title": "Fechar",
            "ok-title": "Vincular",
            "ok-only": "",
          },
        },
        [
          _c(
            "b-form",
            [
              _c(
                "b-card-text",
                [
                  _c(
                    "b-row",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("label", [
                                _vm._v(" Digite o código da turma "),
                              ]),
                              _c(
                                "v-select",
                                {
                                  attrs: {
                                    label: "title",
                                    "item-text": "title",
                                    "item-value": "code",
                                    placeholder: "Digite o código",
                                    options: _vm.optionsClassrooms,
                                  },
                                  on: {
                                    search: _vm.fetchClassrooms,
                                    input: _vm.classroomSelected,
                                  },
                                  model: {
                                    value: _vm.classroom,
                                    callback: function ($$v) {
                                      _vm.classroom = $$v
                                    },
                                    expression: "classroom",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { slot: "no-options" },
                                      slot: "no-options",
                                    },
                                    [_vm._v(" Nenhum registro encontrado ")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "col-12",
                          staticStyle: { "min-height": "250px" },
                        },
                        [
                          _c("p", [_vm._v("Turmas selecionados:")]),
                          _c(
                            "draggable",
                            {
                              staticClass: "list-group mt-1",
                              attrs: {
                                tag: "ul",
                                list: _vm.item.classrooms,
                                handle: ".handle",
                              },
                            },
                            _vm._l(
                              _vm.item.classrooms,
                              function (element, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    staticClass: "list-group-item",
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "handle",
                                      attrs: { icon: "MoveIcon", size: "15" },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "pl-1 text-not-selected" },
                                      [_vm._v(_vm._s(element.title) + " ")]
                                    ),
                                    _c("feather-icon", {
                                      staticClass: "float-right",
                                      attrs: {
                                        icon: "XCircleIcon",
                                        size: "20",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeAtClassroom(index)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "createDiscipline",
            centered: "",
            title: "Selecionar disciplina",
            "hide-footer": "",
            size: "lg",
          },
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitFindDisciplines.apply(null, arguments)
                },
              },
            },
            [
              _c("b-card-text", [
                _c(
                  "div",
                  { staticClass: "form-row justify-content-between" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "align-items-center justify-content-left",
                        attrs: { cols: "12", md: "4" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tag Tema"),
                            ]),
                            _c(
                              "v-select",
                              {
                                ref: "selectTema",
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsTagTema,
                                },
                                on: { search: _vm.fetchTagsCategoryTema },
                                model: {
                                  value: _vm.filters.tag_subject,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "tag_subject", $$v)
                                  },
                                  expression: "filters.tag_subject",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.selectTema.open = false
                                      },
                                    },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-none d-sm-block align-items-center justify-content-left",
                        attrs: { cols: "6", md: "4" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Tag Concurso"),
                            ]),
                            _c(
                              "v-select",
                              {
                                ref: "selectConcurso",
                                attrs: {
                                  label: "title",
                                  "item-text": "title",
                                  "item-value": "code",
                                  placeholder: "Digite o título",
                                  options: _vm.optionsTagConcurso,
                                },
                                on: { search: _vm.fetchTagsCategoryConcurso },
                                model: {
                                  value: _vm.filters.tag_contest,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "tag_contest", $$v)
                                  },
                                  expression: "filters.tag_contest",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    attrs: { slot: "no-options" },
                                    on: {
                                      click: function ($event) {
                                        _vm.$refs.selectConcurso.open = false
                                      },
                                    },
                                    slot: "no-options",
                                  },
                                  [_vm._v(" Nenhum registro encontrado ")]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("b-col", { attrs: { cols: "6", md: "4" } }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tag Linha do tempo"),
                          ]),
                          _c(
                            "v-select",
                            {
                              ref: "selectLinhadoTempo",
                              attrs: {
                                label: "title",
                                "item-text": "title",
                                "item-value": "code",
                                placeholder: "Digite o título",
                                options: _vm.optionsTagLinhaDoTempo,
                              },
                              on: { search: _vm.fetchTagsCategoryLinhaDoTempo },
                              model: {
                                value: _vm.filters.tag_timeline,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "tag_timeline", $$v)
                                },
                                expression: "filters.tag_timeline",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: { slot: "no-options" },
                                  on: {
                                    click: function ($event) {
                                      _vm.$refs.selectLinhadoTempo.open = false
                                    },
                                  },
                                  slot: "no-options",
                                },
                                [_vm._v(" Nenhum registro encontrado ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "b-col",
                      { attrs: { cols: "6", md: "8" } },
                      [
                        _c("b-form-group", [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Título da disciplina"),
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filters.title,
                                expression: "filters.title",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              placeholder: "Digite o título da disciplina",
                            },
                            domProps: { value: _vm.filters.title },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.filters,
                                  "title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                      ],
                      1
                    ),
                    _c("b-col", { attrs: { cols: "6", md: "4" } }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          {
                            staticStyle: { visibility: "hidden" },
                            attrs: { for: "" },
                          },
                          [_vm._v("Buscar")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-block",
                            attrs: {
                              type: "submit",
                              disabled: _vm.submitedFind,
                            },
                          },
                          [
                            _vm.submitedFind
                              ? _c(
                                  "div",
                                  [
                                    _c("b-spinner", {
                                      staticClass: "left",
                                      attrs: { small: "", variant: "light" },
                                    }),
                                    _vm._v(" buscando... "),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "SearchIcon" },
                                    }),
                                    _vm._v(" BUSCAR "),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    !_vm.dataItems.length
                      ? _c(
                          "div",
                          { staticClass: "area-lessons d-block mt-2" },
                          [
                            _vm._v(
                              " Nenhum registro encontrato até o momento. "
                            ),
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-list-group",
                              { staticClass: "area-lessons mb-1" },
                              _vm._l(_vm.dataItems, function (row, index) {
                                return _c("b-list-group-item", { key: index }, [
                                  _c("div", { staticClass: "form-row" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-md-11",
                                        staticStyle: { "line-height": "2" },
                                      },
                                      [
                                        _c("span", { staticClass: "mr-1" }, [
                                          _c("i", {
                                            staticClass: "bi bi-card-list",
                                          }),
                                        ]),
                                        _c("span", [
                                          _vm._v(" " + _vm._s(row.title) + " "),
                                        ]),
                                      ]
                                    ),
                                    _c("div", { staticClass: "col-md-1" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-block btn-sm btn-primary",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.AddItemToArray(row)
                                            },
                                          },
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "PlusCircleIcon",
                                              size: "16",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ])
                              }),
                              1
                            ),
                            _c("CustomPaginateTable", {
                              attrs: {
                                "rows-table": _vm.rowsTable,
                                "current-page": _vm.currentPage,
                                "per-page": _vm.perPage,
                                "total-rows": _vm.totalRows,
                              },
                            }),
                          ],
                          1
                        ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }